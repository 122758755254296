<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Businesses</h4>
        <div class="d-flex align-items-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            class="mr-2"
            @click="reload"
            v-b-tooltip.hover.top="'Reload business profiles'"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-2"
            @click="sortProfile"
            v-b-tooltip.hover.top="'sort acsending or decending'"
          >
            <feather-icon icon="FilterIcon" />
          </b-button>
          <b-form-group
            label=""
            label-for="h-search-keyword"
            label-cols-md="12"
            class="mr-2"
          >
            <b-form-input
              id="h-search-keyword"
              v-model="search_keyword"
              type="text"
              placeholder="Search by business profile name"
              @input="searchByKeyword"
            />
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="showModal"
          >
            New
          </b-button>
        </div>

      </div>

      <div class="business_search_box mb-2">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              label="Search by State "
              label-for="h-search-state"
              label-cols-md="12"
            >
              <b-form-select
                id="h-search-state"
                v-model="search_state"
                :options="state_options"
                style="width: 100%"
                @change="changeStateSearch"
              />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Search by County "
              label-for="h-search-county"
              label-cols-md="12"
            >
              <b-form-select
                id="h-categories"
                v-model="search_county"
                :options="county_options"
                style="width: 100%"
                @change="changeCountySearch"
              />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Search by Category "
              label-for="h-search-category"
              label-cols-md="12"
            >
              <b-form-select
                id="h-search-category"
                v-model="search_category"
                :options="categories_options"
                style="width: 100%"
                @change="changeCategorySearch"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Search by System Status "
              label-for="h-search-state"
              label-cols-md="12"
            >
              <b-form-select
                id="h-search-state"
                v-model="search_system_status"
                :options="system_status_options"
                style="width: 100%"
                @change="changeSystemStatusSearch"
              />
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Search by Application Status "
              label-for="h-search-state"
              label-cols-md="12"
            >
              <b-form-select
                id="h-search-state"
                v-model="search_application_status"
                :options="application_status_options"
                style="width: 100%"
                @change="changeApplicationStatusSearch"
              />
            </b-form-group>
          </div>
        </div>
      </div>
      <b-table
        :items="business_profiles"
        :fields="fields"
        striped
        responsive
        @row-clicked="showDetails"
      >

        <!-- <template #cell(cover)="data">
          <b-avatar :src="getImage(data.value)" />
        </template> -->
        <template #cell(id)="data">
          {{ data.value }}
        </template>
        <template #cell(state_id)="data">
          {{ states.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(county_id)="data">
          {{ allCounties.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(category_id)="data">
          {{ categories.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(system_status)="data">
          <b-badge
            :variant="data.value === 'active' ? 'light-success':
              data.value === 'cancel' ? 'light-danger' : data.value === 'waiting for review' ? 'light-warning' : 'light-secondary'
            "
            style="text-transform: capitalize !important;"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(application_status)="data">
          <b-badge
            :variant="data.value === 'active' ? 'light-success':
              data.value === 'cancel' ? 'light-danger' : data.value === 'waiting for review' ? 'light-warning' : 'light-secondary'
            "
            style="text-transform: capitalize !important;"
          >
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="profile_params.per_page"
        size="lg"
        class="mt-3 pagination-success"
        align="center"
        @change="onPageChange"
      />
    </b-card>

    <BusinessProfileDetails :is_created="is_created"/>
  </div>
</template>

<script>
import {
  BCard, BButton, VBModal,
  BFormGroup, BFormSelect, BFormCheckbox, BPagination, BFormInput, BTable, BBadge, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BusinessProfileDetails from './components/BusinessProfileDetails.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BTable,
    BusinessProfileDetails,
    BBadge,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      is_update: false,
      has_license: false,
      selectedTags: [],
      search_state: null,
      search_county: null,
      search_category: null,
      search_sub_category: null,
      sort_contact_type: 'desc',
      longitude: -78.024902,
      latitude: 37.926868,
      is_edited: false,
      is_created: false,
      map: null,
      map_address: '',
      fields: [{ key: 'id', label: '#ID' }, { key: 'name', label: 'Business Name', sortable: true }, { key: 'state_id', label: 'State', sortable: true }, { key: 'county_id', label: 'County', sortable: true }, { key: 'category_id', label: 'Category', sortable: true }, { key: 'system_status', label: 'System Status', sortable: true }, { key: 'application_status', label: 'Application Status', sortable: true }],
      selected: null,
      isModalShow: false,
      search_keyword: '',
      currentPage: 1,
      system_status_options: [
        { value: 'active', text: 'active' },
        { value: 'cancel', text: 'cancel' },
      ],
      application_status_options: [
        { value: 'active', text: 'active' },
        { value: 'waiting for review', text: 'waiting for review' },
        { value: 'blocked by vendor', text: 'blocked by vendor' },
        { value: 'cancel', text: 'cancel' },
      ],
      search_system_status: null,
      search_application_status: null,
    }
  },
  computed: {
    business_profiles() {
      return this.$store.state.businessProfile.admin_business_profiles
    },
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    totalRows() {
      return this.$store.state.businessProfile.total_admin_count
    },
    profile_reviews() {
      return this.$store.state.review.business_profile_reviews
    },
    totalReviewRows() {
      return this.$store.state.review.total_reviews
    },
    users() {
      const data = this.$store.state.auth.users
      const member = data.filter(item => item.user_type === 'member')
      return member
    },
    user_options() {
      return this.users.map(item => {
        const data = { value: item.member.id, text: `${item.first_name} ${item.last_name}` }
        return data
      })
    },
    categories() {
      return this.$store.state.category.categories
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: `${item.name}` }
        return data
      })
    },
    county_options() {
      return this.counties.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    allCounties() {
      return this.$store.state.county.all_counties
    },
  },
  watch: {
  },
  mounted() {
    this.getBusinessProfile()
  },
  methods: {
    changeStateSearch(id) {
      this.profile_params.sort.direction = ''
      this.profile_params.sort.field = ''
      this.profile_params.states = [id]
      this.getBusinessProfile()
      this.$store.dispatch('county/getCounties', id)
    },
    changeCountySearch(id) {
      this.profile_params.sort.direction = ''
      this.profile_params.sort.field = ''
      this.profile_params.counties = [id]
      this.getBusinessProfile()
    },
    changeCategorySearch(id) {
      this.profile_params.sort.direction = ''
      this.profile_params.sort.field = ''
      this.profile_params.states = []
      this.profile_params.counties = []
      this.profile_params.page = 1
      this.profile_params.categories = [id]
      this.getBusinessProfile()
      this.$store.dispatch('subCategory/getSubCategories', id)
    },
    changeSubCategorySearch(id) {
      this.profile_params.sort.direction = ''
      this.profile_params.sort.field = ''
      this.profile_params.states = []
      this.profile_params.counties = []
      this.profile_params.page = 1
      this.profile_params.sub_categories = [id]
      this.getBusinessProfile()
    },
    searchByKeyword(input) {
      this.profile_params.sort.direction = ''
      this.profile_params.sort.field = ''
      this.profile_params.states = []
      this.profile_params.counties = []
      this.profile_params.categories = []
      this.profile_params.sub_categories = []
      this.profile_params.page = 1
      this.profile_params.search_term = input
      this.getBusinessProfile()
    },
    reload() {
      this.profile_params.states = []
      this.profile_params.counties = []
      this.profile_params.categories = []
      this.profile_params.sub_categories = []
      this.profile_params.search_term = ''
      this.profile_params.sort.direction = ''
      this.profile_params.sort.field = ''
      this.profile_params.page = 1
      this.getBusinessProfile()
    },
    sortProfile() {
      this.profile_params.states = []
      this.profile_params.counties = []
      this.profile_params.categories = []
      this.profile_params.sub_categories = []
      this.profile_params.search_term = ''
      this.profile_params.page = 1
      if (this.sort_contact_type === '' || this.sort_contact_type === 'desc') {
        this.sort_contact_type = 'asc'
      } else if (this.sort_contact_type === 'asc') {
        this.sort_contact_type = 'desc'
      }
      this.profile_params.sort.direction = this.sort_contact_type
      this.profile_params.sort.field = 'name'
      this.getBusinessProfile()
    },
    getDays() {
      return this.$store.dispatch('businessProfile/getDays')
    },
    getBusinessProfile() {
      return this.$store.dispatch('businessProfile/getBusinessProfile', this.profile_params)
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    showFormContent(id) {
      this.is_tab_active = id
    },
    getUsers() {
      return this.$store.dispatch('auth/getUsers', { page: 1, per_page: 10, user_type: 'member' })
    },
    getSubCategory(id) {
      return this.$store.dispatch('subCategory/getSubCategories', id)
    },
    changeCategory() {
      return this.$store.dispatch('subCategory/getSubCategories', this.profile.category_id)
    },
    onPageChange(page) {
      this.profile_params.page = page
      this.getBusinessProfile()
    },
    showModal() {
      this.$bvModal.show('app-modal')
      this.isModalShow = true
      this.is_created = true
      // this.tab_items = this.tab_items.filter(item => item.id !== 9)
    },
    getCounty(id) {
      return this.$store.dispatch('county/getCounties', id)
    },
    toggleBusinessProfile(status, id) {
      const data = { business_profile_id: id, status }
      return this.$store.dispatch('businessProfile/toggleBusinessProfile', data)
    },
    showDetails(item) {
      this.$store.dispatch('businessProfile/getProfileDetails', item)
    },
    changeSystemStatusSearch(item) {
      this.profile_params.system_status = [item]
      this.profile_params.application_status = []
      this.getBusinessProfile()
      this.search_application_status = ''
    },
    changeApplicationStatusSearch(item) {
      this.profile_params.application_status = [item]
      this.profile_params.system_status = []
      this.getBusinessProfile()
      this.search_system_status = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
